import { Injectable } from "@angular/core";
import { ActiveToast, ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  activeToasts: {[key: string]: ActiveToast<any>} = {};

  constructor(private toastr: ToastrService) {}

  showSuccess(message: string, title: string) {
    this.toastr.success(message, title);
  }

  showFailed(message: string, title: string) {
    this.toastr.error(message, title);
  }

  showLoading(key: string) {
    const thisToast = this.toastr.info('Loading...', 'Please wait', {disableTimeOut: true, });
    this.activeToasts[key] = thisToast;
  }

  hideLoading(key: string) {
    setTimeout(() => {
      const toastToRemove = this.activeToasts[key];
      if(toastToRemove) this.toastr.clear(toastToRemove.toastId);
      delete this.activeToasts[key];
    }, 200);
  }
}
