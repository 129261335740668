import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { GET_ALL_KYC_PATH, GET_ALL_MASTER_VARIABLES_PATH, CATEGORIES_PATH, GET_KYC_PATH, GET_MASTER_VARIABLE_PATH, SAVE_KYC_PATH, SAVE_MASTER_VARIABLE_PATH, REGEX_PATH, FILE_UPLOAD_PATH, KYC_RESPONSE_PATH, KYC_RESPONSES_PATH, APPROVAL_STAGES_PATH, KYC_RESPONSE_UPDATE_PATH, KYC_RESPONSE_REMARKS_PATH, UPDATE_KYC_STATUS_PATH, LOGOUT_PATH, GET_TRANSLATION_PATH, SAVE_TRANSLATION_PATH, CREATE_PARTNER_PATH, GET_PARTNERS_PATH, GET_VERSIONS_PATH, GET_GLOBAL_MASTER_VARIABLES_PATH, COPY_MASTER_PATH, ADD_REMARK_PATH, KYC_RESPONSE_FIELDS_PATH, KYC_RESPONSE_UPDATE_FIELDS_STATUS_PATH, KYC_FIELD_RESPONSE_PATH, KYC_RESPONSES_DOWNLOAD_PATH, VERIFY_RESPONSE_PATH, CLONE_KYC_PATH, VERIFICATIONS_PATH, GET_LOGS_PATH, GET_REVIEWS_PATH, SUBMIT_FOR_PRIMARY_REVIEW_PATH, REVIEW_COMPLETE_PATH, SUBMIT_FOR_REVIEW_PATH, MSISDN_RESPONSES_PATH, GET_READY_REVIEWS_PATH, GET_PRODUCT_CATEGORIZATION_PATH, GET_ALL_PRODUCT_CATEGORIZATION_PATH, DELETE_PRODUCT_CATEGORIZATION_PATH, EDIT_PRODUCT_CATEGORIZATION_PATH, ADD_PRODUCT_CATEGORIZATION_PATH, ADD_NEW_PRODUCT_CATEGORIZATION, GET_IMPORTS_PATH, REJECT_IMPORT_PATH, START_IMPORT_PATH, FILE_IMPORT_PATH } from "src/app/static/static.data";

import Regex from "src/app/models/regex.model";
import { AuthService } from "src/app/services/auth.service";
import { NotificationService } from "./notification.service";
import { KYCPermissionLists } from "src/app/shared/permissions";
import { Verification } from "src/app/models/verification.model";
// import { MastersService } from "./masters.service";

@Injectable()
export class ApiService {

  activeNotifications: any[] = [];

  get country() {
    return this.authService.country;
  }

  get partner() {
    return this.authService.partner;
  }

  get accessToken() {
    return this.authService.accessToken;
  }

  getRandomInt() {
    return Math.floor(Math.random() * 1000);
  }

  constructor(private http: HttpClient, private authService: AuthService, private notificationService: NotificationService) {
  }

  private httpOptions(addTenant = true): {headers: HttpHeaders} {
    // show loader in case any call is made
    if(addTenant) {
      return {
        headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.accessToken,
        'Provider': this.authService.getProvider(),
        'x-tenant-id': this.authService.partner?.partnerCode
      })}
    } else {
      return {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.accessToken,
          'Provider': this.authService.getProvider(),
        })
      }
    }
  }

  private downloadCSVOptions(): {headers: HttpHeaders, responseType: "json"} {
    // show loader in case any call is made
    return {
      headers: new HttpHeaders({
        'Accept': 'text/plain, */*',
        'Authorization': 'Bearer ' + this.accessToken,
        'Provider': this.authService.getProvider(),
        'x-tenant-id': this.authService.partner?.partnerCode
      }),
      responseType: "text" as "json"
    }
  }

  private uploadOptions(): {headers: HttpHeaders} {
    return {
      headers: new HttpHeaders({
      'Authorization': 'Bearer ' + this.accessToken,
      'Provider': this.authService.getProvider(),
      'x-tenant-id': this.authService.partner?.partnerCode
    })};
  }

  handleError = (error: HttpErrorResponse, key: string) => {
    if(error.status === 0) {
      console.log('An error occured:', error.error);
    } else {
      console.error(`Backend returned code ${error.status}, with response:`, error.message);
    }
    if(error.error) {
      console.log("Server message: ", error.error);
    }
    if(error.status === 401) {
      this.authService.logout();
    }
    // in case of error - remove the loading indicator
    this.notificationService.hideLoading(key);
    return throwError(error.message);
  }

  private getData(response: any, field: string, key?: string) {
    if(key) this.notificationService.hideLoading(key);
    if(field in response && response.statusCode === "200") return response[field];
    return response;
  }

  private getGlobals() {
    const country = this.country?.code.toLowerCase() || "";
    const partner = this.partner?.partnerCode || "";
    return {country, partner};
  }

  getKYC(editId: string): Observable<any> {
    if(!this.authService.hasPermission(KYCPermissionLists.getKycPermissions)) return throwError("You don't have permission to view KYC");
    const key = "getKYC-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    return this.http.get<any>(GET_KYC_PATH + '?id=' + editId, this.httpOptions())
      .pipe(
        map((data: any) => this.getData(data, 'data', key)),
        catchError((err) => this.handleError(err, key))
    );
  }

  getAllKYC(): Observable<any> {
    if(!this.authService.hasPermission(KYCPermissionLists.listKycPermissions)) return throwError("You don't have permission to view all KYC");
    const {country, partner} = this.getGlobals();
    const key = "getAllKYC-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    return this.http.get<any>(GET_ALL_KYC_PATH + `?country=${country}&partner=${partner}`, this.httpOptions())
      .pipe(
        map((data: any) => this.getData(data, 'data', key)),
        catchError((err) => this.handleError(err, key))
    );
  }

  saveKYC(kyc: string): Observable<any> {
    if(!this.authService.hasPermission(KYCPermissionLists.createKycPermissions)) return throwError("You don't have permission to save/edit KYC");
    const key = "saveKYC-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    return this.http.post<string>(SAVE_KYC_PATH, kyc, this.httpOptions())
      .pipe(
        map((data: any) => this.getData(data, 'data', key)),
        catchError((err) => this.handleError(err, key))
      );
  }

  cloneKYC(kycId: string): Observable<any> {
    if(!this.authService.hasPermission(KYCPermissionLists.createKycPermissions)) return throwError("You don't have permission to save/edit KYC");
    const key = "cloneKYC-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    return this.http.post<string>(CLONE_KYC_PATH + '?kycId=' + kycId, null, this.httpOptions())
      .pipe(
        map((data: any) => this.getData(data, 'data', key)),
        catchError((err) => this.handleError(err, key))
      );
  }

  updateKycStatus(kycId: string | null | undefined, status: string): Observable<any> {
    if(!this.authService.hasPermission(KYCPermissionLists.updateKycStatusPermissions)) return throwError("You don't have permission to change KYC status");
    const key = "updateKycStatus-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    return this.http.post<string>(UPDATE_KYC_STATUS_PATH, {kycId, status}, this.httpOptions())
      .pipe(
        map((data: any) => this.getData(data, 'data', key)),
        catchError((err) => this.handleError(err, key))
      );
  }

  getTranslation(lang: string): Observable<any> {
    return this.http.get(GET_TRANSLATION_PATH + lang).pipe(
      map((res: any) => res.data)
    );
  }

  saveTranslation(language: string, definition: {[key: string]: string}): Observable<any> {
    if(!this.authService.hasPermission(KYCPermissionLists.editLanguagePermissions)) return throwError("You don't have permission to edit translations");
    const key = "saveTranslation-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    return this.http.post<string>(SAVE_TRANSLATION_PATH + language, definition, this.httpOptions(false))
      .pipe(
        map((data: any) => this.getData(data, 'data', key)),
        catchError((err) => this.handleError(err, key))
      );
  }

  saveMasterVariable(masterVariable: string): Observable<any> {
    if(!this.authService.hasPermission(KYCPermissionLists.createMasterVariablePermissions)) return throwError("You don't have permission to save/edit master variables");
    const key = "saveMasterVariable-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    return this.http.post<string>(SAVE_MASTER_VARIABLE_PATH, masterVariable, this.httpOptions())
      .pipe(
        map((data: any) => this.getData(data, 'data', key)),
        catchError((err) => this.handleError(err, key))
      );
  }

  getAllMasterVariables(masterType: string): Observable<any> {
    if(!this.authService.hasPermission(KYCPermissionLists.getMasterVariablePermissions)) return throwError("You don't have permission to view all master variables");
    const {country, partner} = this.getGlobals();
    const key = "getAllMasterVariables-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    return this.http.get<any>(GET_ALL_MASTER_VARIABLES_PATH + `?country=${country}&partner=${partner}&masterType=${masterType}`, this.httpOptions())
      .pipe(
        map((data: any) => this.getData(data, 'data', key)),
        catchError((err) => this.handleError(err, key))
      );
  }

  getMasterVariable(id: string): Observable<any> {
    if(!this.authService.hasPermission(KYCPermissionLists.getMasterVariablePermissions)) return throwError("You don't have permission to fetch master variables");
    const key = "getMasterVariable-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    return this.http.get<any>(GET_MASTER_VARIABLE_PATH + "?id=" + id, this.httpOptions())
      .pipe(
        map((data: any) => this.getData(data, 'data', key)),
        catchError((err) => this.handleError(err, key))
      );
  }

  getGlobalMasterVariables(): Observable<any> {
    if(!this.authService.hasPermission(KYCPermissionLists.listMasterVariablesPermissions)) return throwError("You don't have permission to fetch master variables");
    const key = "getGlobalMasterVariable-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    return this.http.get<any>(GET_GLOBAL_MASTER_VARIABLES_PATH, this.httpOptions(false))
      .pipe(
        map((data: any) => this.getData(data, 'data', key)),
        catchError((err) => this.handleError(err, key))
      );
  }

  copyMaster(id: string): Observable<any> {
    if(!this.authService.hasPermission(KYCPermissionLists.createMasterVariablePermissions)) return throwError("You don't have permission to edit master variables");
    const {country, partner} = this.getGlobals();
    const key = "copyMaster-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    return this.http.post<string>(COPY_MASTER_PATH, {id, country, partner}, this.httpOptions())
      .pipe(
        map((data: any) => this.getData(data, 'data', key)),
        catchError((err) => this.handleError(err, key))
      );
  }

  getCategories(): Observable<any> {
    if(!this.authService.hasPermission(KYCPermissionLists.getCategoryPermissions)) return throwError("You don't have permission to view all categories");
    const {country, partner} = this.getGlobals();
    const key = "getCategories-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    return this.http.get<any>(CATEGORIES_PATH + `?country=${country}&partner=${partner}`, this.httpOptions())
      .pipe(
        map((data: any) => this.getData(data, 'data', key)),
        catchError((err) => this.handleError(err, key))
      );
  }

  saveCategories(categories: any): Observable<any> {
    if(!this.authService.hasPermission(KYCPermissionLists.editCategoryPermissions)) return throwError("You don't have permission to edit categories");
    const key = "saveCategories-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    return this.http.post<string>(CATEGORIES_PATH, categories, this.httpOptions())
      .pipe(
        map((data: any) => this.getData(data, 'data', key)),
        catchError((err) => this.handleError(err, key))
      );
  }

  getRegex(): Observable<any> {
    if(!this.authService.hasPermission(KYCPermissionLists.getMasterVariablePermissions)) return throwError("You don't have permission to fetch master variables");
    const {country, partner} = this.getGlobals();
    const key = "getRegex-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    return this.http.get<any>(REGEX_PATH + `?country=${country}&partner=${partner}`, this.httpOptions())
      .pipe(
        map((data: any) => this.getData(data, 'data', key)),
        catchError((err) => this.handleError(err, key))
      );
  }

  getVerifications(): Observable<any> {
    if(!this.authService.hasPermission(KYCPermissionLists.getMasterVariablePermissions)) return throwError("You don't have permission to fetch master variables");
    const {country, partner} = this.getGlobals();
    const key = "getVerifications-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    return this.http.get<any>(VERIFICATIONS_PATH + `?country=${country}&partner=${partner}`, this.httpOptions())
      .pipe(
        map((data: any) => this.getData(data, 'data', key)),
        catchError((err) => this.handleError(err, key))
      );
  }

  saveRegex(regex: Regex): Observable<any> {
    if(!this.authService.hasPermission(KYCPermissionLists.createMasterVariablePermissions)) return throwError("You don't have permission to save/edit master variables");
    const key = "saveRegex-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    return this.http.post<string>(REGEX_PATH, regex, this.httpOptions())
      .pipe(
        map((data: any) => this.getData(data, 'data', key)),
        catchError((err) => this.handleError(err, key))
      );
  }

  saveVerification(verification: Verification): Observable<any> {
    if(!this.authService.hasPermission(KYCPermissionLists.createMasterVariablePermissions)) return throwError("You don't have permission to save/edit master variables");
    const key = "saveVerification-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    return this.http.post<string>(VERIFICATIONS_PATH, verification, this.httpOptions())
      .pipe(
        map((data: any) => this.getData(data, 'data', key)),
        catchError((err) => this.handleError(err, key))
      );
  }

  getApprovalStages(): Observable<any> {
    if(!this.authService.hasPermission(KYCPermissionLists.getApprovalStagesPermissions)) return throwError("You don't have permission to view all approval stages");
    const {country, partner} = this.getGlobals();
    const key = "getApprovalStages-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    return this.http.get<any>(APPROVAL_STAGES_PATH + `?country=${country}&partner=${partner}`, this.httpOptions())
      .pipe(
        map((data: any) => this.getData(data, 'data', key)),
        catchError((err) => this.handleError(err, key))
      );
  }

  saveApprovalStages(approvalStages: any): Observable<any> {
    if(!this.authService.hasPermission(KYCPermissionLists.editApprovalStagesPermissions)) return throwError("You don't have permission to edit approval stages");
    const key = "saveApprovalStages-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    return this.http.post<string>(APPROVAL_STAGES_PATH, approvalStages, this.httpOptions())
      .pipe(
        map((data: any) => this.getData(data, 'data', key)),
        catchError((err) => this.handleError(err, key))
      );
  }

  uploadFile(file: File, kycId: string, fieldId: string): Observable<any> {
    if(!this.authService.hasPermission(KYCPermissionLists.uploadFilePermissions)) return throwError("You don't have permission to upload files");
    const formData = new FormData();
    formData.append("file", file);
    formData.append("kycId", kycId);
    formData.append("fieldId", fieldId);
    const uploadOptions = this.uploadOptions();
    const key = "uploadFile-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    return this.http.post(FILE_UPLOAD_PATH, formData, uploadOptions)
    .pipe(
      map((data: any) => this.getData(data, 'data', key)),
      catchError((err) => this.handleError(err, key))
    );
  }

  saveKYCResponse(kyc: string): Observable<any> {
    if(!this.authService.hasPermission(KYCPermissionLists.submitKycResponsePermissions)) return throwError("You don't have permission to submit a KYC response");
    const key = "saveKYCResponse-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    return this.http.post<string>(KYC_RESPONSE_PATH, kyc, this.httpOptions())
      .pipe(
        map((data: any) => this.getData(data, 'data', key)),
        catchError((err) => this.handleError(err, key))
      );
  }

  saveKYCFieldResponse(kyc: string): Observable<any> {
    if(!this.authService.hasPermission(KYCPermissionLists.submitKycResponsePermissions)) return throwError("You don't have permission to submit a KYC response");
    const key = "saveKYCFieldResponse-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    return this.http.post<string>(KYC_FIELD_RESPONSE_PATH, kyc, this.httpOptions())
      .pipe(
        map((data: any) => this.getData(data, 'data', key)),
        catchError((err) => this.handleError(err, key))
      );
  }

  getKYCResponses(kycId: string | null, msisdn: string | null = '', status: string | null = 'ALL', page: number | null = 0, count: number | null = 1): Observable<any> {
    if(!this.authService.hasPermission(KYCPermissionLists.reviewKycResponsePermissions)) return throwError("You don't have permission to view KYC responses");
    const key = "getKYCResponses-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    let url = KYC_RESPONSES_PATH + "?fields=true&kycId=" + (kycId ? kycId : "") + "&status=" + (status === 'ALL' ? '' : status) + "&page=" + page + "&count=" + count;
    if(!kycId) {
      const {country, partner} = this.getGlobals();
      url = KYC_RESPONSES_PATH + "?fields=true&status=" + status + "&country=" + country + "&partner=" + partner + "&page=" + page + "&count=" + count;
    }
    if(msisdn) {
      url += ("&msisdn=" + msisdn);
    }
    return this.http.get<string>(url, this.httpOptions())
      .pipe(
        map((data: any) => this.getData(data, 'data', key)),
        catchError((err) => this.handleError(err, key))
      );
  }

  getMsisdnResponses(msisdn: string): Observable<any> {
    if (!this.authService.hasPermission(KYCPermissionLists.reviewKycResponsePermissions)) return throwError("You don't have permission to view KYC responses");
    const key = "getMsisdnResponses-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    const { country, partner } = this.getGlobals();
    let url = MSISDN_RESPONSES_PATH + `?msisdn=${msisdn}&country=${country}&partner=${partner}`;
    return this.http.get<string>(url, this.httpOptions())
      .pipe(
        map((data: any) => this.getData(data, 'data', key)),
        catchError((err) => this.handleError(err, key))
      );
  }

  downloadResponses(kycId: string): Observable<any> {
    if(!this.authService.hasPermission(KYCPermissionLists.listKycResponsesPermissions)) return throwError("You don't have permission to download KYC responses");
    const key = "downloadResponses-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    return this.http.get<string>(KYC_RESPONSES_DOWNLOAD_PATH + "?kycId=" + kycId, this.httpOptions())
      .pipe(
        map((data: any) => this.getData(data, 'data', key)),
        catchError((err) => this.handleError(err, key))
      );
  }

  getKYCResponse(id: string): Observable<any> {
    if(!this.authService.hasPermission(KYCPermissionLists.listKycResponsesPermissions)) return throwError("You don't have permission to view KYC responses");
    const key = "getKYCResponse-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    return this.http.get<string>(KYC_RESPONSE_PATH + "?id=" + id, this.httpOptions())
      .pipe(
        map((data: any) => this.getData(data, 'data', key)),
        catchError((err) => this.handleError(err, key))
      );
  }

  getKYCResponseFields(msisdn: string, kycId: string): Observable<any> {
    if(!this.authService.hasPermission(KYCPermissionLists.listKycResponsesPermissions)) return throwError("You don't have permission to view KYC responses");
    const key = "getKYCResponse-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    return this.http.get<string>(KYC_RESPONSE_FIELDS_PATH + `?msisdn=${msisdn}&kycId=${kycId}`, this.httpOptions())
      .pipe(
        map((data: any) => this.getData(data, 'data', key)),
        catchError((err) => this.handleError(err, key))
      );
  }

  updateKycResponseStage(id: string, status: string, msisdn: string): Observable<any> {
    if(!this.authService.hasPermission(KYCPermissionLists.reviewKycResponsePermissions)) return throwError("You don't have permission to update KYC response stage");
    const key = "updateKYCResponseStage-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    return this.http.post<string>(KYC_RESPONSE_UPDATE_PATH, {id, status, msisdn}, this.httpOptions())
      .pipe(
        map((data: any) => this.getData(data, 'data', key)),
        catchError((err) => this.handleError(err, key))
      );
  }

  updateKycResponseFieldsStage(id: string, msisdn: string, status: string): Observable<any> {
    if(!this.authService.hasPermission(KYCPermissionLists.reviewKycResponsePermissions)) return throwError("You don't have permission to update KYC response stage");
    const key = "updateKYCResponseFieldsStatus-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    return this.http.post<string>(KYC_RESPONSE_UPDATE_FIELDS_STATUS_PATH, {id, status, msisdn}, this.httpOptions())
      .pipe(
        map((data: any) => this.getData(data, 'data', key)),
        catchError((err) => this.handleError(err, key))
      );
  }

  saveFieldRemark(remarkObject: any) {
    if (!this.authService.hasPermission(KYCPermissionLists.reviewKycResponsePermissions)) return throwError("You don't have permission to review KYC response");
    const key = "saveFieldRemark-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    return this.http.post<string>(KYC_RESPONSE_REMARKS_PATH, remarkObject, this.httpOptions())
      .pipe(
        map((data: any) => this.getData(data, '', key)),
        catchError((err) => this.handleError(err, key))
      );
  }

  logout(): void {
    this.http.post<string>(LOGOUT_PATH, {}, this.httpOptions()).subscribe(() => {
      console.log("Logged out");
    });
    this.authService.logout();
  }

  createPartner(partnerId: string | undefined, partnerName: string, partnerCode: string, authUrl: string, verifyTokenUrl: string, fullPartnerCode: string, packageId: string, os: string, clientSecret: string, selectedCountries: string[], selectedLanguages: string[]): Observable<any> {
    if(!this.authService.hasPermission(KYCPermissionLists.adminPermissions)) return throwError("You don't have permission to create a partner");
    const partnerObject: any = {partnerName, partnerCode, authUrl, verifyTokenUrl, fullPartnerCode, packageId, os, clientSecret, countries: selectedCountries, languages: selectedLanguages};
    if(partnerId) partnerObject.id = partnerId;
    const key = "createPartner-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    return this.http.post<string>(CREATE_PARTNER_PATH, partnerObject, this.httpOptions())
      .pipe(
        map((data: any) => this.getData(data, 'data', key)),
        catchError((err) => this.handleError(err, key))
      );
  }

  getPartners(): Observable<any> {
    if(!this.authService.hasPermission(KYCPermissionLists.adminPermissions)) return throwError("You don't have permission to view partners");
    const key = "getPartners-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    return this.http.get<string>(GET_PARTNERS_PATH, this.httpOptions())
      .pipe(
        map((data: any) => this.getData(data, 'data', key)),
        catchError((err) => this.handleError(err, key))
      );
  }

  fetchVersions(id: string, currentVersion: number): Observable<any> {
    if(!this.authService.hasPermission(KYCPermissionLists.listKycPermissions)) return throwError("You don't have permission to view all KYC");
    const key = "fetchVersions-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    return this.http.get<string>(GET_VERSIONS_PATH + "?kycId=" + id + "&currentVersion=" + currentVersion, this.httpOptions())
      .pipe(
        map((data: any) => this.getData(data, 'data', key)),
        catchError((err) => this.handleError(err, key))
      );
  }

  saveRemark(id: string, remark: string): Observable<any> {
    if(!this.authService.hasPermission(KYCPermissionLists.reviewKycPermissions)) return throwError("You don't have permission to review KYC");
    const key = "addRemark-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    return this.http.post<string>(ADD_REMARK_PATH, {kycId: id, remark}, this.httpOptions())
      .pipe(
        map((data: any) => this.getData(data, 'data', key)),
        catchError((err) => this.handleError(err, key))
      );
  }

  verifyResponse(kycId: string, fieldId: string, body: any): Observable<any> {
    if (!this.authService.hasPermission(KYCPermissionLists.submitKycResponsePermissions)) return throwError("You don't have permission to submit a KYC response");
    const key = "verifyResponse-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    return this.http.post<string>(VERIFY_RESPONSE_PATH, {kycId, fieldId, body}, this.httpOptions())
      .pipe(
        map((data: any) => this.getData(data, '', key)),
        catchError((err) => this.handleError(err, key))
      );
  }

  getLogs(logsType: string, msisdn: string | null = null, userName: string | null = null, status: string | null = null, dateRangeStart: string | null = null, dateRangeEnd: string | null = null, page: string | number | null = null, count: string | number | null = null): Observable<any> {
    if (!this.authService.hasPermission(KYCPermissionLists.adminPermissions)) return throwError("You don't have permission to see server logs");
    const key = "getLogs-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    return this.http.get<string>(GET_LOGS_PATH + `?type=${logsType}&msisdn=${msisdn}&userName=${userName}&status=${status}&dateRangeStart=${dateRangeStart ?? ""}&dateRangeEnd=${dateRangeEnd ?? ""}&page=${page}&count=${count}`, this.httpOptions(false))
      .pipe(
        map((data: any) => this.getData(data, 'data', key)),
        catchError((err) => this.handleError(err, key))
      );
  }

  getReviews(review: string): Observable<any> {
    if (!this.authService.hasPermission(KYCPermissionLists.adminPermissions)) return throwError("You don't have permission to review responses");
    const key = "getReviews-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    return this.http.get<string>(review === 'READY' ? GET_READY_REVIEWS_PATH : GET_REVIEWS_PATH + `?reviewType=${review}`, this.httpOptions())
      .pipe(
        map((data: any) => this.getData(data, 'data', key)),
        catchError((err) => this.handleError(err, key))
      );
  }

  submitForReview(msisdn: string, kycId: string) {
    if (!this.authService.hasPermission(KYCPermissionLists.submitKycResponsePermissions)) return throwError("You don't have permission to review responses");
    const key = "submitForReview-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    return this.http.post<string>(SUBMIT_FOR_REVIEW_PATH + `?msisdn=${msisdn}&kycId=${kycId}`, null, this.httpOptions())
      .pipe(
        map((data: any) => this.getData(data, 'data', key)),
        catchError((err) => this.handleError(err, key))
      );
  }

  submitDecision(msisdn: string, kycId: string, decision: boolean, reviewType: string) {
    if (!this.authService.hasPermission(KYCPermissionLists.adminPermissions)) return throwError("You don't have permission to review responses");
    const key = "submitDecision-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    return this.http.post<string>((reviewType === 'PRIMARY' ? SUBMIT_FOR_PRIMARY_REVIEW_PATH : REVIEW_COMPLETE_PATH) + `?msisdn=${msisdn}&kycId=${kycId}&decision=${decision}`, null, this.httpOptions())
      .pipe(
        map((data: any) => this.getData(data, 'data', key)),
        catchError((err) => this.handleError(err, key))
      );
  }

  getAllProductCategorizations() {
    if (!this.authService.hasPermission(KYCPermissionLists.adminPermissions)) return throwError("You don't have permission to fetch product categorizations");
    const key = "getAllProductCategories-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    return this.http.get<string>(GET_ALL_PRODUCT_CATEGORIZATION_PATH, this.httpOptions())
      .pipe(
        map((data: any) => this.getData(data, 'data', key)),
        catchError((err) => this.handleError(err, key))
      );
  }

  getProductCategorization(id: string) {
    if (!this.authService.hasPermission(KYCPermissionLists.adminPermissions)) return throwError("You don't have permission to fetch product categorizations");
    const key = "getProductCategories-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    return this.http.get<string>( GET_PRODUCT_CATEGORIZATION_PATH + `?id=${id}`, this.httpOptions())
      .pipe(
        map((data: any) => this.getData(data, 'data', key)),
        catchError((err) => this.handleError(err, key))
      );
  }

  deleteProductCategory(id: string) {
    if (!this.authService.hasPermission(KYCPermissionLists.adminPermissions)) return throwError("You don't have permission to edit product categorizations");
    const key = "deleteProductCategory-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    return this.http.delete<string>(DELETE_PRODUCT_CATEGORIZATION_PATH + `?id=${id}`, this.httpOptions())
      .pipe(
        map((data: any) => this.getData(data, 'data', key)),
        catchError((err) => this.handleError(err, key))
      );
  }

  editProductCategory(id: string, name: string) {
    if (!this.authService.hasPermission(KYCPermissionLists.adminPermissions)) return throwError("You don't have permission to edit product categorizations");
    const key = "editProductCategory-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    return this.http.post<string>(EDIT_PRODUCT_CATEGORIZATION_PATH + `?id=${id}&name=${name}`, null, this.httpOptions())
      .pipe(
        map((data: any) => this.getData(data, 'data', key)),
        catchError((err) => this.handleError(err, key))
      );
  }

  addProductCategory(parentId: string, name: string) {
    if (!this.authService.hasPermission(KYCPermissionLists.adminPermissions)) return throwError("You don't have permission to add product categorizations");
    const key = "addProductCategory-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    return this.http.post<string>(ADD_PRODUCT_CATEGORIZATION_PATH + `?id=${parentId}&name=${encodeURIComponent(name)}&type=child`, null, this.httpOptions())
      .pipe(
        map((data: any) => this.getData(data, 'data', key)),
        catchError((err) => this.handleError(err, key))
      );
  }

  createNewCategorization(name: string) {
    if (!this.authService.hasPermission(KYCPermissionLists.adminPermissions)) return throwError("You don't have permission to create a new product categorization");
    const key = "addNewProductCategory-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    return this.http.post<string>(ADD_NEW_PRODUCT_CATEGORIZATION + `?name=${name}`, null, this.httpOptions())
      .pipe(
        map((data: any) => this.getData(data, 'data', key)),
        catchError((err) => this.handleError(err, key))
      );
  }

  getImports(): Observable<any> {
    if (!this.authService.hasPermission(KYCPermissionLists.adminPermissions)) return throwError("You don't have permission to import responses");
    const key = "getImports-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    return this.http.get<any>(GET_IMPORTS_PATH, this.httpOptions())
      .pipe(
        map((data: any) => this.getData(data, 'data', key)),
        catchError((err) => this.handleError(err, key))
      );
  }

  rejectImport(kycId: string): Observable<any> {
    if (!this.authService.hasPermission(KYCPermissionLists.adminPermissions)) return throwError("You don't have permission to reject an import");
    const key = "rejectImport-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    return this.http.post<string>(REJECT_IMPORT_PATH + `?kycId=${kycId}`, null, this.httpOptions())
      .pipe(
        map((data: any) => this.getData(data, 'data', key)),
        catchError((err) => this.handleError(err, key))
      );
  }

  startImport(fileName: string | null): Observable<any> {
    if (!this.authService.hasPermission(KYCPermissionLists.adminPermissions)) return throwError("You don't have permission to start an import");
    const key = "startImport-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    return this.http.post<string>( START_IMPORT_PATH + ( fileName ? `?fileName=${fileName}` : ""), null, this.httpOptions())
      .pipe(
        map((data: any) => this.getData(data, 'data', key)),
        catchError((err) => this.handleError(err, key))
      );
  }

  uploadImport(file: File): Observable<any> {
    if (!this.authService.hasPermission(KYCPermissionLists.adminPermissions)) return throwError("You don't have permission to import files");
    const formData = new FormData();
    formData.append("file", file);
    const uploadOptions = this.uploadOptions();
    const key = "uploadFile-" + this.getRandomInt();
    this.notificationService.showLoading(key);
    return this.http.post(FILE_IMPORT_PATH, formData, uploadOptions)
      .pipe(
        map((data: any) => this.getData(data, 'data', key)),
        catchError((err) => this.handleError(err, key))
      );
  }

}
