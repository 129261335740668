import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopnavComponent } from './components/topnav/topnav.component';
import { MainContainerComponent } from './components/main-container/main-container.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { NgMaterialModule } from '../ng-material/ng-material.module';
import { BaseLayoutComponent } from './components/base-layout/base-layout.component';
import { RouterModule } from '@angular/router';
import { MinLayoutComponent } from './components/min-layout/min-layout.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    TopnavComponent,
    SidenavComponent,
    MainContainerComponent,
    BaseLayoutComponent,
    MinLayoutComponent,
  ],
  imports: [CommonModule, NgMaterialModule, RouterModule, SharedModule],
  exports: [BaseLayoutComponent, MinLayoutComponent],
})
export class LayoutModule {}
