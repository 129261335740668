import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'yabx-new-admin-portal';

  constructor(private translate: TranslateService, private authService: AuthService) {
    const currentLanguage = authService.language ? authService.language["1"] : "en";
    // default language
    translate.setDefaultLang(currentLanguage);

    // language to use if language definition not available
    translate.use(currentLanguage);
  }
}
