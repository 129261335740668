import { Action, createReducer, on } from "@ngrx/store";
import { setCountryAction, setPartnerAction } from "./global.actions";
import { globals } from "./global.model";

export const initialState: globals = {country: '', partner: ''};

const _globalReducer = createReducer(
  initialState,
  on(setCountryAction, (state, action) => {return {...state, country: action.payload.country};}),
  on(setPartnerAction, (state, action) => {return {...state, partner: action.payload.partner};})
);

export function globalReducer(state: globals | undefined, action: Action) {
  return _globalReducer(state, action);
}
