import { Map } from "typescript"

export const KYCPermission = {
  GetKYCDetails: "get_kyc_details",
  ListKYCs: "list_kyc",
  CreateKYC: "create_kyc",
  EditKYC: "edit_kyc",
  ReviewKYC: "review_kyc",
  ChangeKYCStatus: "change_kyc_status",
  GetApprovalPath: "get_approval_path",
  EditApprovalPath: "create_approval_path",
  GetMasterVariableDetails: "get_master_variable_details",
  CreateMasterVariable: "create_master_variable",
  EditMasterVariable: "edit_master_variable",
  ChangeMasterVariableStatus: "change_master_variable_status",
  ListMasterVariables: "list_master_variables",
  GetCategories: "get_categories",
  EditCategories: "edit_categories",
  SubmitKYCResponse: "submit_kyc_response",
  ReviewKYCResponse: "review_kyc_response",
  ChangeKYCResponseStatus: "change_kyc_response_status",
  ListKYCResponses: "list_kyc_responses",
  EditLanguages: "edit_languages",
  KYCAdmin: "kyc_admin",
}

type permList = {
  getKycPermissions: string
  listKycPermissions: string
  createKycPermissions: string
  reviewKycPermissions: string
  updateKycStatusPermissions: string
  createMasterVariablePermissions: string
  listMasterVariablesPermissions: string
  getMasterVariablePermissions: string
  editLanguagePermissions: string
  getCategoryPermissions: string
  editCategoryPermissions: string
  getApprovalStagesPermissions: string
  editApprovalStagesPermissions: string
  submitKycResponsePermissions: string
  reviewKycResponsePermissions: string
  listKycResponsesPermissions: string
  adminPermissions: string
}

export class KYCPermissionListsDefinition {
  public getKycPermissions = [KYCPermission.GetKYCDetails, KYCPermission.KYCAdmin];
  public listKycPermissions = [KYCPermission.ListKYCs, KYCPermission.KYCAdmin];
  public createKycPermissions = [KYCPermission.CreateKYC, KYCPermission.EditKYC, KYCPermission.KYCAdmin];
  public reviewKycPermissions = [KYCPermission.ReviewKYC, KYCPermission.KYCAdmin];
  public updateKycStatusPermissions = [KYCPermission.ChangeKYCStatus, KYCPermission.KYCAdmin];
  public createMasterVariablePermissions = [KYCPermission.CreateMasterVariable, KYCPermission.EditMasterVariable, KYCPermission.KYCAdmin];
  public listMasterVariablesPermissions = [KYCPermission.ListMasterVariables, KYCPermission.CreateKYC, KYCPermission.KYCAdmin];
  public getMasterVariablePermissions = [KYCPermission.GetMasterVariableDetails, KYCPermission.KYCAdmin];
  public editLanguagePermissions = [KYCPermission.EditLanguages, KYCPermission.KYCAdmin];
  public getCategoryPermissions = [KYCPermission.GetCategories, KYCPermission.CreateKYC, KYCPermission.CreateMasterVariable, KYCPermission.EditMasterVariable, KYCPermission.KYCAdmin];
  public editCategoryPermissions = [KYCPermission.EditCategories, KYCPermission.KYCAdmin];
  public getApprovalStagesPermissions = [KYCPermission.GetApprovalPath, KYCPermission.EditApprovalPath, KYCPermission.KYCAdmin];
  public editApprovalStagesPermissions = [KYCPermission.EditApprovalPath, KYCPermission.KYCAdmin];
  public submitKycResponsePermissions = [KYCPermission.SubmitKYCResponse, KYCPermission.KYCAdmin];
  public uploadFilePermissions = [KYCPermission.SubmitKYCResponse, KYCPermission.CreateKYC, KYCPermission.EditKYC, KYCPermission.KYCAdmin];
  public reviewKycResponsePermissions = [KYCPermission.ReviewKYCResponse, KYCPermission.KYCAdmin];
  public listKycResponsesPermissions = [KYCPermission.ListKYCResponses, KYCPermission.KYCAdmin];
  public adminPermissions = [KYCPermission.KYCAdmin];
}

export const KYCPermissionLists = new KYCPermissionListsDefinition();
