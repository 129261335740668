<ng-container>
<!-- <ng-container *ngIf="layout$ | async"> -->
  <div fxLayout="column" fxLayoutAlign="start stretch" class="layout-wrapper">
    <mat-toolbar color="primary" *ngIf="this.authService.isUserLoggedIn() && this.showHeader">
      <button
        mat-icon-button
        (click)="drawer.toggle()"
      >
        <mat-icon *ngIf="drawer.opened">navigate_before</mat-icon>
        <mat-icon *ngIf="!drawer.opened">navigate_next</mat-icon>
      </button>
      <span>{{ 'INTERFACE-HEADER-TITLE' | translate }}</span>
      <span class="spacer"></span>
      <mat-icon style="margin-left: 15px;">flag</mat-icon>
      <mat-form-field class="header-select" *ngIf="this.availableCountries && this.availableCountries.length > 1">
        <input type="text" matInput [formControl]="countryControl" [matAutocomplete]="autoCountry">
        <mat-autocomplete autoActiveFirstOption #autoCountry="matAutocomplete" [displayWith]="displayCountryName"
          (optionSelected)="setCountry($event)">
          <mat-option *ngFor="let country of availableCountries" [value]="country">
            {{country.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <span class="header-select" *ngIf="this.availableCountries && this.availableCountries.length === 1">
        {{this.availableCountries[0].name}}
      </span>
      <mat-icon style="margin-left: 15px;">business</mat-icon>
      <mat-form-field class="header-select" *ngIf="this.availablePartners && this.availablePartners.length > 1">
        <input type="text" matInput [formControl]="partnerControl" [matAutocomplete]="autoPartner">
        <mat-autocomplete autoActiveFirstOption #autoPartner="matAutocomplete" [displayWith]="displayPartnerName"
          (optionSelected)="setPartner($event)">
          <mat-option *ngFor="let partner of this.availablePartners" [value]="partner">
            {{partner.partnerName}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <span class="header-select" *ngIf="this.availablePartners && this.availablePartners.length === 1">
        {{this.availablePartners[0].partnerName}}
      </span>
      <mat-icon style="margin-left: 15px;">language</mat-icon>
      <mat-form-field class="header-select" *ngIf="this.availableLanguages && this.availableLanguages.length > 1">
        <input type="text" matInput [formControl]="languageControl" [matAutocomplete]="autoLanguage">
        <mat-autocomplete autoActiveFirstOption #autoLanguage="matAutocomplete" [displayWith]="displayLanguageName"
          (optionSelected)="setLanguage($event)">
          <mat-option *ngFor="let lang of this.availableLanguages" [value]='lang'>
            {{lang.name}} ({{lang.local}})
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <span class="header-select" *ngIf="this.availableLanguages && this.availableLanguages.length === 1">
        {{this.availableLanguages[0].name}}
      </span>
      <div class="header-image" [matMenuTriggerFor]="menu" [style]="'background-image: url(\'' + this.authService.getUserPhoto() + '\')'"></div>

      <mat-menu #menu="matMenu">
        <mat-card class="mat-elevation-z0">
          <mat-card-header>
            <div mat-card-avatar class="header-image" [style]="'background-image: url(\'' + this.authService.getUserPhoto() + '\')'"></div>
            <mat-card-title>{{this.authService.getUserName()}}</mat-card-title>
            <mat-card-subtitle>{{this.authService.getEmail()}}</mat-card-subtitle>
          </mat-card-header>

          <mat-card-actions>
            <div fxLayout="row" fxLayoutAlign="space-between start" style="text-align: right;">
              <!-- <button mat-button>Profile</button> -->
              <button mat-button (click)="logout()">{{ 'BUTTON-LOGOUT' | translate }}</button>
            </div>
          </mat-card-actions>
        </mat-card>
      </mat-menu>
    </mat-toolbar>

    <mat-drawer-container class="sidenav-container" autosize>
      <mat-drawer #drawer class="sidenav" mode="over" [style]="this.showHeader ? '' : 'display: none;'">
        <mat-expansion-panel expanded>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Stored Templates
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-nav-list>
            <mat-list-item routerLink="/kyc/list/ci" (click)="drawer.close()" *ngIf="this.authService.hasPermission(this.listKYCPermissions)">
              <a matLine>
                KYC Templates
              </a>
            </mat-list-item>
            <mat-list-item routerLink="/kyc/list/ac" (click)="drawer.close()" *ngIf="this.authService.hasPermission(this.listKYCPermissions)">
              <a matLine>
                App Control Templates
              </a>
            </mat-list-item>
            <mat-list-item routerLink="/kyc/list/sc" (click)="drawer.close()" *ngIf="this.authService.hasPermission(this.listKYCPermissions)">
              <a matLine>
                Scoring Templates
              </a>
            </mat-list-item>
            <mat-list-item routerLink="/kyc/list/md" (click)="drawer.close()" *ngIf="this.authService.hasPermission(this.listKYCPermissions)">
              <a matLine>
                Metadata Templates
              </a>
            </mat-list-item>
          </mat-nav-list>
        </mat-expansion-panel>
        <mat-nav-list>
          <mat-list-item routerLink="/kyc/responses" (click)="drawer.close()" *ngIf="this.authService.hasPermission(this.listKYCResponsesPermissions)">
            <a matLine >
              {{"MENU-KYC-RESPONSES" | translate}}
            </a>
          </mat-list-item>
          <mat-list-item routerLink="/kyc/master/list-packages" (click)="drawer.close()" *ngIf="this.authService.hasPermission(this.listMasterPermissions)" >
            <a matLine >
              {{"MENU-STORED-MASTER-PACKAGES" | translate}}
            </a>
          </mat-list-item>
          <mat-list-item routerLink="/kyc/master/list-segments" (click)="drawer.close()" *ngIf="this.authService.hasPermission(this.listMasterPermissions)" >
            <a matLine >
              {{"MENU-STORED-MASTER-SEGMENTS" | translate}}
            </a>
          </mat-list-item>
          <mat-list-item routerLink="/kyc/master/list-groups" (click)="drawer.close()" *ngIf="this.authService.hasPermission(this.listMasterPermissions)" >
            <a matLine >
              {{"MENU-STORED-MASTER-GROUPS" | translate}}
            </a>
          </mat-list-item>
          <mat-list-item routerLink="/kyc/master/list-fields" (click)="drawer.close()" *ngIf="this.authService.hasPermission(this.listMasterPermissions)" >
            <a matLine >
              {{"MENU-STORED-MASTER-FIELDS" | translate}}
            </a>
          </mat-list-item>
          <mat-list-item routerLink="/kyc/master/list-options" (click)="drawer.close()" *ngIf="this.authService.hasPermission(this.listMasterPermissions)" >
            <a matLine >
              {{"MENU-STORED-MASTER-OPTIONS" | translate}}
            </a>
          </mat-list-item>
          <mat-list-item routerLink="/kyc/browse-master/" (click)="drawer.close()" *ngIf="this.authService.hasPermission(this.listMasterPermissions)" >
            <a matLine>
              {{"MENU-BROWSE-MASTERS" | translate}}
            </a>
          </mat-list-item>
          <mat-list-item routerLink="/kyc/categories" (click)="drawer.close()" *ngIf="this.authService.hasPermission(categoryListPermissions)">
            <a matLine >
              {{"MENU-CATEGORIES" | translate}}
            </a>
          </mat-list-item>
          <mat-list-item routerLink="/kyc/regex" (click)="drawer.close()" *ngIf="this.authService.hasPermission(this.listMasterPermissions)">
            <a matLine >
              {{"MENU-REGEX" | translate}}
            </a>
          </mat-list-item>
          <mat-list-item routerLink="/kyc/productCategorization" (click)="drawer.close()" *ngIf="this.authService.hasPermission(this.listMasterPermissions)">
            <a matLine>
              {{"MENU-PRODUCT-CATEGORIZATION" | translate}}
            </a>
          </mat-list-item>
          <!-- <mat-list-item routerLink="/kyc/approval" (click)="drawer.close()" *ngIf="this.authService.hasPermission(this.listApprovalStagesPermissions)">
            <a matLine >
              {{"MENU-APPROVAL-STAGES" | translate}}
            </a>
          </mat-list-item> -->
          <mat-list-item routerLink="/kyc/verifications" (click)="drawer.close()" *ngIf="this.authService.hasPermission(this.listMasterPermissions)">
            <a matLine >
              {{"MENU-VERIFICATIONS" | translate}}
            </a>
          </mat-list-item>
          <mat-list-item routerLink="/kyc/reviews" (click)="drawer.close()" *ngIf="this.authService.hasPermission(this.adminPermissions)">
            <a matLine>
              {{"MENU-REVIEWS" | translate}}
            </a>
          </mat-list-item>
          <mat-list-item routerLink="/kyc/languages" (click)="drawer.close()" *ngIf="this.authService.hasPermission(this.listLanguagePermissions)">
            <a matLine >
              Language Definitions
            </a>
          </mat-list-item>
          <mat-list-item routerLink="/kyc/partners" (click)="drawer.close()" *ngIf="this.authService.hasPermission(this.adminPermissions)">
            <a matLine >
              Partners
            </a>
          </mat-list-item>
          <mat-list-item routerLink="/kyc/imports" (click)="drawer.close()" *ngIf="this.authService.hasPermission(this.adminPermissions)">
            <a matLine>
              {{"MENU-IMPORTS" | translate}}
            </a>
          </mat-list-item>
          <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{"LOGS-VIEW" | translate}}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list>
              <mat-list-item routerLink="/kyc/logs/user" (click)="drawer.close()"
                *ngIf="this.authService.hasPermission(this.listKYCPermissions)">
                <a matLine>
                  User Logs
                </a>
              </mat-list-item>
              <mat-list-item routerLink="/kyc/logs/customer" (click)="drawer.close()"
                *ngIf="this.authService.hasPermission(this.listKYCPermissions)">
                <a matLine>
                  Customer Logs
                </a>
              </mat-list-item>
            </mat-nav-list>
          </mat-expansion-panel>
        </mat-nav-list>
      </mat-drawer>

      <router-outlet></router-outlet>
    </mat-drawer-container>
  </div>
</ng-container>

<!-- <ng-container *ngIf="!(layout$ | async)">
  <router-outlet></router-outlet>
</ng-container> -->
