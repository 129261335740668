import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { ApiService } from "./api.service";
import { catchError, share, tap } from "rxjs/operators";
import { reviewType } from "src/app/models/reviewType";

@Injectable({
  providedIn: 'root'
})
export class ReviewsService {
  reviews: {[key in reviewType]: any[] | null} = {
    PRIMARY: null,
    SECONDARY: null,
    READY: null
  };

  reviewRequests: {[key in reviewType]: Observable<any> | null} = {
    PRIMARY: null,
    SECONDARY: null,
    READY: null
  }

  constructor(private apiService: ApiService) {}

  getReviews(review: reviewType): Observable<any> {
    if (this.reviewRequests[review]) {
      return this.reviewRequests[review]!;
    }
    if (this.reviews[review]) {
      return of(this.reviews[review]);
    }
    return this.refreshReviews(review);
  }

  refreshReviews(review: reviewType): Observable<any> {
    const thisRequest = this.apiService.getReviews(review).pipe(
      tap((theseReviews: any[]) => {
        this.reviewRequests[review] = null;
        this.reviews[review] = theseReviews;
      }),
      share(),
      catchError(err => {
        this.reviewRequests[review] = null;
        this.reviews[review] = [];
        return throwError(err);
      })
    );
    this.reviewRequests[review] = thisRequest;
    return thisRequest;
  }

  moveReview(msisdn: string, kycId: string, reviewType: reviewType) {
    if(!reviewType) reviewType = "READY";
    const relevantList = this.reviews[reviewType];
    if(relevantList) {
      for(let i = 0; i<relevantList.length; i++) {
        const r = relevantList[i];
        if(r.msisdn === msisdn && r.kycId === kycId) {
          const removedElements = relevantList.splice(i, 1);
          const newReviewType = reviewType === 'PRIMARY' ? 'SECONDARY' : 'PRIMARY';
          removedElements.forEach(re => {
            re.type = newReviewType;
          });
          if (this.reviews[newReviewType]) {
            this.reviews[newReviewType] = this.reviews[newReviewType]?.concat(removedElements) || null;
          } else {
            this.reviews[newReviewType] = removedElements;
          }
          break;
        }
      }
    }
  }
}
