import { HttpClient } from "@angular/common/http";
import { TranslateLoader } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { environment } from "src/environments/environment";
import { NotificationService } from "../kyc/services/notification.service";
import { GET_TRANSLATION_PATH } from "../static/static.data";

export class TranslationLoader implements TranslateLoader {
  constructor(private http: HttpClient, private notificationService: NotificationService) { }

  getTranslation(lang: string): Observable<any> {
    return this.http.get( GET_TRANSLATION_PATH + lang).pipe(
      map((res: any) => {
        if(res.statusCode === "200") {
          return res.data;
        }
        this.notificationService.showFailed(res.message, "Unavailable");
      })
    );
  }
}


export function HttpLoaderFactory(http: HttpClient, notificationService: NotificationService) {
  return new TranslationLoader(http, notificationService);
}
