import { Component, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgMaterialModule } from '../ng-material/ng-material.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';

const ANCILLARY_NG_MODULES = [
  FormsModule,
  ReactiveFormsModule,
  HttpClientModule,
  NgSelectModule
];

const SHARED_COMPONENTS = [];

@NgModule({
  declarations: [],
  imports: [CommonModule, ...ANCILLARY_NG_MODULES, NgMaterialModule, TranslateModule],
  exports: [...ANCILLARY_NG_MODULES, TranslateModule],
})
export class SharedModule {}
