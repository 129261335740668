import { createAction, props } from "@ngrx/store";
import { globals } from "./global.model";

export const setCountryAction = createAction(
  'Set Country',
  props<{payload: globals}>()
);
export const setPartnerAction = createAction(
  'Set Partner',
  props<{payload: globals}>()
);
