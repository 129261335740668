export const AUTH_URL_FRAGMENT = 'auth/rm/login';
export const USER_INFO_TOKEN = 'user_info';
export const LOGIN_PATH = 'auth/login';
export const HOME_PATH = '/kyc/list/ci';
export const AUTH_PATH = '/authentication/login';
export const LOGOUT_PATH = '/authentication/logout';
export const SAVE_KYC_PATH = '/kyc2/save';
export const CLONE_KYC_PATH = '/kyc2/cloneKyc';
export const GET_ALL_KYC_PATH = '/kyc2/getAll';
export const GET_KYC_PATH = '/kyc2/get';
export const UPDATE_KYC_STATUS_PATH = '/kyc2/updateStatus';
export const SAVE_MASTER_VARIABLE_PATH = '/kyc2/saveMaster';
export const GET_MASTER_VARIABLE_PATH = '/kyc2/getMaster';
export const GET_GLOBAL_MASTER_VARIABLES_PATH = '/kyc2/getGlobalMasters';
export const GET_ALL_MASTER_VARIABLES_PATH = "/kyc2/getAllMasters";
export const COPY_MASTER_PATH = "/kyc2/copyMaster";
export const REGEX_PATH = "/kyc2/regex";
export const VERIFICATIONS_PATH = "/kyc2/verifications";
export const FILE_UPLOAD_PATH = "/kyc2/upload";
export const FILE_DOWNLOAD_PATH = "/kyc2/getDocument";
export const GET_TRANSLATION_PATH = "/kyc2/translations/";
export const SAVE_TRANSLATION_PATH = "/kyc2/edit-translations/"
export const CATEGORIES_PATH = "/kyc2/categories";
export const KYC_RESPONSE_PATH = "/kyc2/kycResponse";
export const KYC_FIELD_RESPONSE_PATH = "/kyc2/kycFieldResponse";
export const KYC_RESPONSE_FIELDS_PATH = "/kyc2/kycResponseDetails";
export const KYC_RESPONSE_UPDATE_FIELDS_STATUS_PATH = "/kyc2/kycResponseDetailsUpdateStatus";
export const KYC_RESPONSES_PATH = "/kyc2/kycResponses";
export const MSISDN_RESPONSES_PATH = "/kyc2/msisdnResponses";
export const KYC_RESPONSES_DOWNLOAD_PATH = "/kyc2/kycResponsesCSV";
export const KYC_RESPONSE_UPDATE_PATH = "/kyc2/kycResponseUpdate";
export const KYC_RESPONSE_REMARKS_PATH = "/kyc2/kycResponseRemark";
export const APPROVAL_STAGES_PATH = "/kyc2/approvalStages";
export const CREATE_PARTNER_PATH = "/kyc2/createPartner";
export const GET_PARTNERS_PATH = "/kyc2/getPartners";
export const GET_VERSIONS_PATH = "/kyc2/getVersions";
export const ADD_REMARK_PATH = "/kyc2/addRemark";
export const VERIFY_RESPONSE_PATH = "/kyc2/verifyResponse";
export const GET_LOGS_PATH = "/kyc2/logs";
export const GET_REVIEWS_PATH = "/kyc2/getReviews";
export const GET_READY_REVIEWS_PATH = "/kyc2/responsesReadyForReview";
export const SUBMIT_FOR_REVIEW_PATH = "/kyc2/submitForReview";
export const SUBMIT_FOR_PRIMARY_REVIEW_PATH = "/kyc2/submitForSecondaryReview";
export const REVIEW_COMPLETE_PATH = "/kyc2/markReviewComplete";
export const GET_PRODUCT_CATEGORIZATION_PATH = "/kyc2/getProductCategorizationTree";
export const GET_ALL_PRODUCT_CATEGORIZATION_PATH = "/kyc2/getAllProductCategorizations";
export const DELETE_PRODUCT_CATEGORIZATION_PATH = "/kyc2/deleteProductCategorizationNode";
export const EDIT_PRODUCT_CATEGORIZATION_PATH = "/kyc2/renameProductCategorizationNode";
export const ADD_PRODUCT_CATEGORIZATION_PATH = "/kyc2/addProductCategorizationNode";
export const ADD_NEW_PRODUCT_CATEGORIZATION = "/kyc2/createNewProductCategorization";
export const GET_IMPORTS_PATH = "/kyc2/getImports";
export const REJECT_IMPORT_PATH = "/kyc2/rejectImport";
export const START_IMPORT_PATH = "/kyc2/startImport";
export const FILE_IMPORT_PATH = "/kyc2/importFile";

export const GOOGLE_CLIENT_ID = "710971288127-otg3kms61mqi1hgcofu1lcd70ops073j.apps.googleusercontent.com";

export const availableLanguages = [{code: 'en', name: 'English'}, {code: 'hi', name: 'हिंदी'}];
export const availableCountries = [{code: 'in', name: 'India'}, {code: 'ga', name: 'Gabon'}, {code: 'ke', name: 'Kenya'}, {code: 'rw', name: 'Rwanda'}];
export const availablePartners = [{code: 'air', name: 'Airtel'}, {code: 'yab', name: 'YABX'}, {code: 'jio', name: 'Jio'}, {code: 'vod', name: 'Vodafone/Idea'}, {code: 'bsn', name: 'BSNL'}];
export const kycStatusList = [{value: "saved", display: "Submitted"}, {value: "reviewed", display: "Reviewed"}, {value: "published", display: "Published"}, {value: "rejected", display: "Rejected"}];


//  Functions
export const URLRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;

export const approvalStages = [
  "ALL",
  "CREATED",
  "SUBMITTED",
  "REVIEWED",
  "RESUBMITTED",
  "APPROVED",
  "REJECTED",
  "PENDING_REVIEW",
  "OTHER",
];
