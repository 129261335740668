import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { AUTH_PATH, LOGIN_PATH } from '../static/static.data';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
// import { CookieService } from './cookies.service';
import {CookieService} from 'ngx-cookie-service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Language, where } from 'langs';
import { getName } from 'country-list';
import { Store } from '@ngrx/store';
import { setCountryAction, setPartnerAction } from '../kyc/store/global.actions';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  user: any = null;

  partner: any = null; //stores partner object
  language: Language | undefined | null; // stores language object
  country: {code: string, name: string} | undefined = undefined;

  partnerChange: Subject<any> = new Subject<any>();
  countryChange: Subject<{code: string, name: string}> = new Subject<{code: string, name: string}>();
  languageChange: Subject<Language> = new Subject<Language>();

  availableLanguages: Language[] | null = null;
  availablePartners: any[] | null = null;
  availableCountries: {code: string, name: string}[] | null = null;

  availableLanguagesChange: Subject<Language[]> = new Subject<Language[]>();
  availablePartnersChange: Subject<any> = new Subject<any>();
  availableCountriesChange: Subject<{code: string, name: string}[]> = new Subject<{code: string, name: string}[]>();

  accessToken: string | undefined | null = null;
  accessTokenChange: Subject<string> = new Subject<string>();

  permissions: string[] | undefined | null = null;
  permissionsChange: Subject<any> = new Subject<any>();

  constructor(private router: Router, private cookieService: CookieService, private http: HttpClient, private store: Store, private translate: TranslateService) {
    this.availableLanguagesChange.subscribe((value) => {
      this.availableLanguages = value;
    });
    this.availableCountriesChange.subscribe((value) => {
      this.availableCountries = value;
    });
    this.availablePartnersChange.subscribe((value) => {
      this.availablePartners = value;
    });
    this.partnerChange.subscribe((value) => {
      this.partner = value;
    });
    this.countryChange.subscribe((value) => {
      this.country = value;
    });
    this.languageChange.subscribe((value) => {
      this.language = value;
    });
    this.accessTokenChange.subscribe((value) => {
      this.accessToken = value;
    });
    this.permissionsChange.subscribe((value) => {
      this.permissions = value;
    })

    // For page refreshes - if there is any cookie stored language, partner, country, we can reload them
    this.loadStoredPartner();
    this.loadStoredCountry();
    this.loadStoredLanguage();
    this.loadStoredAvailablePartners();
    this.loadStoredAvailableCountries();
    this.loadStoredAvailableLanguages();
    this.loadStoredAccessToken();
    this.loadStoredPermissions();
  }

  storeCookies(data: any, userLogin: boolean = true) {
    const accessToken = data.provider === 'GOOGLE' ? data.idToken : data.access_token;
    this.storeAccessToken(accessToken);

    localStorage.setItem("user", JSON.stringify(data));
    this.cookieService.set("email", data.email, undefined, '/', window.location.hostname);
    this.cookieService.set("name", data.name, undefined, '/', window.location.hostname);

    if(data.provider === 'GOOGLE') {
      this.cookieService.set("user_id", data.id, undefined, '/', window.location.hostname);
      this.cookieService.set("provider", 'GOOGLE', undefined, '/', window.location.hostname);

      // handle YABX login
      this.cookieService.set("partner_code", "yabx", undefined, '/', window.location.hostname);
      this.cookieService.set("availablePartners", JSON.stringify(['yabx']), undefined, '/', window.location.hostname);
      this.availablePartnersChange.next([{partnerCode: "yabx", partnerName: "YABX"}]);

      let yabxPartnerFound = false;
      for(const partner of data.partners) {
        if(partner.partnerCode === 'yabx') {
          this.storePartner(partner);
          yabxPartnerFound = true;
          break;
        }
      }
      if(!yabxPartnerFound) {
        this.storePartner({partnerCode: "yabx", partnerName: "YABX", languages: ["en"]});
      }

      //updating store so that pages can refresh
      this.store.dispatch(setPartnerAction({payload: {partner: 'yabx'}}));

      this.cookieService.set("country", 'global', undefined, '/', window.location.hostname);
      this.storeCountry({code: 'global', name: 'Global'});
      this.store.dispatch(setCountryAction({payload: {country: 'global'}}));

      this.availableCountriesChange.next([{code: 'global', name: 'Global'}]);
      this.cookieService.set("availableCountries", JSON.stringify(['global']), undefined, '/', window.location.hostname);

    } else {
      this.cookieService.set("refresh_token", data.refresh_token, undefined, '/', window.location.hostname);
      this.cookieService.set("user_id", data.user_id, undefined, '/', window.location.hostname);
      this.cookieService.set("provider", '', undefined, '/', window.location.hostname);
      this.cookieService.set("partner_code", data.partner_code, undefined, '/', window.location.hostname);
      const partnerFragments = data.partner_code.split("_");
      if(partnerFragments.length > 1) {
        const partnerName = partnerFragments[0];
        const partnerCountry = partnerFragments[1];
        const matchingPartners = data.partners.filter((p: any) => p.partnerCode === partnerName);
        const newPartner = { partnerName: partnerName, partnerCode: partnerName, countries: [partnerCountry], languages: ["en"] };
        if(!userLogin && matchingPartners.length === 0) {
          data.partners.push(newPartner);
          this.partner = newPartner;
          this.storePartner(newPartner);
          this.store.dispatch(setPartnerAction({ payload: { partner: partnerName } }));
          const countryCode = partnerFragments[1].toLowerCase();
          const countryName = getName(countryCode);
          this.country = { code: countryCode, name: countryName ?? ""};
          this.storeCountry(this.country);
          this.store.dispatch(setCountryAction({payload: {country: countryCode}}));
        }
      }

      this.cookieService.set("availablePartners", JSON.stringify(data.partners), undefined, '/', window.location.hostname);
      this.availablePartnersChange.next(data.partners);

      this.loadStoredPartner();

      if(!this.partner || data.partners.filter((p: any) => p.partnerCode === this.partner.partnerCode).length === 0) {
        this.storePartner(data.partners[0]);
        //updating store so that pages can refresh
        this.store.dispatch(setPartnerAction({payload: {partner: data.partners[0].partnerCode}}));
      }

      this.loadStoredCountry();

      if(!this.country || this.partner.countries.indexOf(this.country?.code?.toLowerCase()) === -1) {
        this.cookieService.set("country", this.partner.countries[0].toLowerCase(), undefined, '/', window.location.hostname);
        const lookupCountry = getName(this.partner.countries[0]);
        if(lookupCountry) {
          this.storeCountry({code: data.partners[0].countries[0].toLowerCase(), name: lookupCountry});
          this.store.dispatch(setCountryAction({payload: {country: data.partners[0].countries[0].toLowerCase()}}))
        }
      }

      this.availableCountriesChange.next(this.partner ? this.partner.countries.map((c: any) => { return {code: c, name: getName(c)}}) : []);
      this.cookieService.set("availableCountries", JSON.stringify(this.partner ? this.partner.countries : []), undefined, '/', window.location.hostname);

      this.storePermissions(data.permissions);
    }

    this.loadStoredLanguage();

    if(!this.language || data.partners[0].languages.indexOf(this.language[1]) === -1) {
      if(data.partners[0].languages && data.partners[0].languages.length > 0) {
        const lang = where("1", data.partners[0].languages[0]);
        if(lang) this.storeLanguage(lang);
      } else {
        // defaulting to english if there is nothing that we can do
        this.languageChange.next(where("1", "en"));
      }
    }

    this.availableLanguagesChange.next(this.partner && this.partner.languages ? this.partner.languages.map((l: any) => where("1", l)) : []);
    this.cookieService.set("availableLanguages", JSON.stringify(this.partner ? this.partner.languages : []), undefined, '/', window.location.hostname);

  }

  loadStoredPartner() {
    const storedPartner = this.cookieService.get("partner");
    if(storedPartner) {
      const storedPartnerJSON = JSON.parse(storedPartner);
      this.partnerChange.next(storedPartnerJSON);
      this.store.dispatch(setPartnerAction({payload: {partner: storedPartnerJSON}}));
    }
  }

  storePartner(partner: any) {
    this.cookieService.set("partner", JSON.stringify(partner), undefined, '/', window.location.hostname);
    this.partnerChange.next(partner);
  }

  updatePartnerIfCurrent(partner: any) {
    if(this.partner.partnerCode === partner.partnerCode) {
      this.storePartner(partner);
      this.availableLanguagesChange.next(partner.languages.map((l: any) => where("1", l)));
      this.cookieService.set("availableLanguages", JSON.stringify(partner.languages ? partner.languages : ['en']), undefined, '/', window.location.hostname);
      this.availableCountriesChange.next(partner.countries.map((c: any) => { return {code: c, name: getName(c)}}));
      this.cookieService.set("availableCountries", JSON.stringify(partner.countries ? partner.countries : ['in']), undefined, '/', window.location.hostname);
    }
  }

  loadStoredCountry() {
    const currentCountry = this.cookieService.get("country");
    if(currentCountry) {
      const lookupCountry = getName(currentCountry);
      if(lookupCountry) {
        this.countryChange.next({code: currentCountry, name: lookupCountry});
        this.store.dispatch(setCountryAction({payload: {country: currentCountry}}));
      }
    }
  }

  storeCountry(country: any) {
    this.cookieService.set("country", country.code, undefined, '/', window.location.hostname);
    this.countryChange.next(country);
  }

  loadStoredLanguage() {
    const currentLanguage = this.cookieService.get("language");
    if(currentLanguage) {
      this.languageChange.next(where("1", currentLanguage));
    }
  }

  storeLanguage(language: Language) {
    this.cookieService.set("language", language[1], undefined, '/', window.location.hostname);
    this.translate.use(language[1]);
    this.languageChange.next(language);
  }

  loadStoredAvailablePartners() {
    const storedAvailablePartners = this.cookieService.get("availablePartners");
    if(storedAvailablePartners) {
      const storedAvailablePartnersJSON = JSON.parse(storedAvailablePartners);
      this.availablePartnersChange.next(storedAvailablePartnersJSON);
    }
  }

  loadStoredAvailableCountries() {
    const storedAvailableCountries = this.cookieService.get("availableCountries");
    if(storedAvailableCountries) {
      const storedAvailableCountriesJSON = JSON.parse(storedAvailableCountries);
      this.availableCountriesChange.next(storedAvailableCountriesJSON.map((c: any) => { return {code: c, name: getName(c)}}));
    }
  }

  loadStoredAvailableLanguages() {
    const storedAvailableLanguages = this.cookieService.get("availableLanguages");
    if(storedAvailableLanguages) {
      const storedAvailableLanguagesJSON = JSON.parse(storedAvailableLanguages);
      this.availableLanguagesChange.next(storedAvailableLanguagesJSON.map((l: any) => where("1", l)));
    }
  }

  loadStoredAccessToken() {
    const storedAccessToken = this.cookieService.get("access_token");
    if(storedAccessToken) {
      this.accessTokenChange.next(storedAccessToken);
    }
  }

  storeAccessToken(accessToken: string) {
    this.accessTokenChange.next(accessToken);
    this.cookieService.set("access_token", accessToken, undefined, '/', window.location.hostname);
  }

  loadStoredPermissions() {
    const storedPermissions = this.cookieService.get("permissions");
    if(storedPermissions) {
      const storedPermissionsJSON = JSON.parse(storedPermissions);
      this.permissionsChange.next(storedPermissionsJSON);
    }
  }

  storePermissions(permissions: string) {
    this.permissionsChange.next(permissions);
    this.cookieService.set("permissions", JSON.stringify(permissions), undefined, '/', window.location.hostname);
  }

  removeCookies(): void {
    this.cookieService.delete("access_token", "/", window.location.hostname);
    this.cookieService.delete("email", "/", window.location.hostname);
    this.cookieService.delete("name", "/", window.location.hostname);
    this.cookieService.delete("refresh_token", "/", window.location.hostname);
    this.cookieService.delete("user_id", "/", window.location.hostname);
    this.cookieService.delete("provider", "/", window.location.hostname);
    this.cookieService.delete("partner_code", "/", window.location.hostname);
    this.cookieService.delete("availablePartners", "/", window.location.hostname);
    this.cookieService.delete("availableCountries", "/", window.location.hostname);
    this.cookieService.delete("availableLanguages", "/", window.location.hostname);
    this.cookieService.delete("permissions", "/", window.location.hostname);
    localStorage.removeItem("user");
  }

  logout(): void {
    this.user = null;
    this.accessTokenChange.next("");
    this.removeCookies();
    this.router.navigate([LOGIN_PATH]);
  }

  isUserLoggedIn(): boolean {
    if(this.user && this.user.access_token) return true;
    this.loadStoredAccessToken();
    if(this.accessToken) {
      try {
        const storedUser = localStorage.getItem("user");
        if(storedUser) {
          this.user = JSON.parse(storedUser);
          return true;
        } else {
          return false;
        }
      } catch(e) {
        return false;
      }
    }
    return false;
  }

  getUserPhoto(): string {
    return (this.user && this.user.photoUrl) ? this.user.photoUrl : "assets/person.png";
  }

  getUserName(): string {
    return (this.user && this.user.name) ? this.user.name : 'Unnamed User';
  }

  getEmail(): string {
    return (this.user && this.user.email) ? this.user.email : "Email unavailable";
  }

  getAccessToken(): string {
    return this.accessToken || "";
  }

  getProvider(): string {
    return (this.user && this.user.provider) ? this.user.provider : '';
  }

  // getLanguage(): Language | null {
  //   if(this.language) return this.language;
  //   const storedLanguage = this.cookieService.get("language");
  //   if(storedLanguage) {
  //     const lookupLanguage = where("1", storedLanguage);
  //     if(lookupLanguage) {
  //       this.language = lookupLanguage;
  //       return this.language;
  //     }
  //   }
  //   return null;
  // }

  // getCountry() {
  //   if(this.country) return this.country;
  //   const storedCountry = this.cookieService.get("country");
  //   if(storedCountry) {
  //     const lookupCountry = getName(storedCountry);
  //     if(lookupCountry) {
  //       this.country = {code: storedCountry.toLowerCase(), name: lookupCountry};
  //       return this.country;
  //     }
  //   }
  //   return null;
  // }

  // getPartner() {
  //   if(this.partner) return this.partner;
  //   const storedPartner = this.cookieService.get("partner");
  //   if(storedPartner) {
  //     return JSON.parse(storedPartner);
  //   }
  // }

  // getAvailableLanguages() {
  //   if(this.availableLanguages) return this.availableLanguages;
  //   const storedAvailableLanguages = this.cookieService.get("availableLanguages");
  //   if(storedAvailableLanguages) {
  //     try {
  //       const languages = JSON.parse(storedAvailableLanguages);
  //       this.availableLanguagesChange.next(languages);
  //       return languages;
  //     } catch(e) {
  //       return [];
  //     }
  //   }
  //   return [];
  // }

  // getAvailableCountries() {
  //   if(this.availableCountries) return this.availableCountries;
  //   const storedAvailableCountries = this.cookieService.get("availableCountries");
  //   if(storedAvailableCountries) {
  //     try {
  //       const countries = JSON.parse(storedAvailableCountries);
  //       this.availableCountriesChange.next(countries);
  //       return countries;
  //     } catch(e) {
  //       return [];
  //     }
  //   }
  //   return [];
  // }

  // getAvailablePartners() {
  //   if(this.availablePartners) return this.availablePartners;
  //   const storedAvailablePartners = this.cookieService.get("availablePartners");
  //   if(storedAvailablePartners) {
  //     try {
  //       const partners = JSON.parse(storedAvailablePartners);
  //       this.availablePartnersChange.next(partners);
  //       return partners;
  //     } catch(e) {
  //       return [];
  //     }
  //   }
  //   return [];
  // }

  // setPartner(partner: any) {
  //   if(partner) {
  //     this.partnerChange.next(partner);
  //     this.cookieService.set("partner", JSON.stringify(partner), undefined, '/', window.location.hostname);
  //   }
  // }

  // setCountry(country: {code: string, name: string}) {
  //   if(country) {
  //     this.countryChange.next(country);
  //     this.cookieService.set("country", country.code.toLowerCase(), undefined, '/', window.location.hostname);
  //   }
  // }

  // setLanguage(language: Language) {
  //   if(language) {
  //     this.languageChange.next(language);
  //     this.cookieService.set("language", language["1"], undefined, '/', window.location.hostname);
  //   }
  // }

  setUser(user: any) {
    if(user) {
      this.user = user;
      this.storeAccessToken(user.access_token);
    }
  }

  private handleError(error: HttpErrorResponse) {
    if(error.status === 0) {
      console.log('An error occured:', error.error);
    } else {
      console.error(`Backend returned code ${error.status}, with response:`, error.message);
    }
    return throwError(error.message);
  }

  saveData(loginData: any, userLogin: boolean = true) {
    this.user = loginData;
    this.storeCookies(loginData, userLogin);
  }

  private handleResponse(response: any) {
    if(response.statusCode === "200" && 'data' in response) {
      this.saveData(response.data);
      return response.data;
    }
    return response;
  }

  login(form: any) {
    this.storeCountry(form.countryCode);
    this.storePartner(form.partnerCode);
    const headers = new HttpHeaders({ 'x-tenant-id': form.partnerCode, 'Accept': 'application/json' });
    return this.http.post(AUTH_PATH, form, {headers: headers})
      .pipe(
        map((data: any) => this.handleResponse(data)),
        catchError(this.handleError)
      );
  }

  hasPermission(permissionList: string[]) {
    return (this.permissions && permissionList.some(r=> this.permissions!.includes(r)));
  }
}
