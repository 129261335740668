import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { HOME_PATH, LOGIN_PATH } from 'src/app/static/static.data';
import { setCountryAction, setPartnerAction } from 'src/app/kyc/store/global.actions';
import { ApiService } from 'src/app/kyc/services/api.service';
import { SocialAuthService } from 'angularx-social-login';
import { Language } from 'langs';
import { MastersService } from 'src/app/kyc/services/masters.service';
import { KYCPermissionLists } from 'src/app/shared/permissions';
import user from 'src/app/static/loginData';
import { FieldDataService } from 'src/app/kyc/services/fieldData.service';

@Component({
  selector: 'app-main-container',
  templateUrl: './main-container.component.html',
  styleUrls: ['./main-container.component.scss'],
  providers: [SocialAuthService, FieldDataService]
})
export class MainContainerComponent implements OnInit, AfterViewInit {
  layoutSubject = new BehaviorSubject<boolean>(true);
  layout$ = this.layoutSubject.asObservable();
  languageControl = new FormControl();
  countryControl: FormControl = new FormControl();
  partnerControl: FormControl = new FormControl();

  get availableLanguages(): Language[] {
    return this.authService.availableLanguages || [];
  }

  get availableCountries(): any[] {
    return this.authService.availableCountries || [];
  }

  get availablePartners() {
    return this.authService.availablePartners || [];
  }

  get country(): {code: string, name: string} | undefined {
    return this.authService.country;
  }

  get language(): Language | undefined | null {
    return this.authService.language;
  }

  get partner(): any {
    return this.authService.partner || {};
  }

  listKYCPermissions: string[] = [...KYCPermissionLists.listKycPermissions, ...KYCPermissionLists.createKycPermissions];
  listKYCResponsesPermissions: string[] = [...KYCPermissionLists.reviewKycResponsePermissions, ...KYCPermissionLists.listKycResponsesPermissions];
  listMasterPermissions: string[] = [...KYCPermissionLists.createMasterVariablePermissions, ...KYCPermissionLists.listMasterVariablesPermissions];
  listApprovalStagesPermissions: string[] = [...KYCPermissionLists.getApprovalStagesPermissions, ...KYCPermissionLists.editApprovalStagesPermissions];
  listLanguagePermissions: string[] = [...KYCPermissionLists.editLanguagePermissions];
  adminPermissions: string[] = [...KYCPermissionLists.adminPermissions];
  categoryListPermissions: string[] = [...KYCPermissionLists.getCategoryPermissions];

  showHeader: boolean = true;

  constructor(private router: Router, public authService: AuthService, private translate: TranslateService, public store: Store, private apiService: ApiService, private socialAuthService: SocialAuthService, private masterService: MastersService, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.showHeader = params.showHeader === 'false' ? false : true;
    });

    // backdoor to login user
    this.activatedRoute.params.subscribe(params => {
      const searchParams = new URLSearchParams(window.location.search);
      const access_token = searchParams.get('access_token');
      let partnerCode: string | null = searchParams.get('partnerCode') ?? '';
      const container = window.frameElement;
      if(container) {
        partnerCode = container.getAttribute('partner_code');
      }
      if (partnerCode) user.partner_code = partnerCode;
      if (access_token) {
        user.access_token = access_token;
        this.authService.saveData(user, false);
      } else if (!authService.isUserLoggedIn() && !window.location.href.includes("/login")) {
        this.router.navigate([LOGIN_PATH]);
      }
      this.authService.countryChange.subscribe(country => {
        this.countryControl = new FormControl(country);
      });
      this.authService.languageChange.subscribe(language => {
        this.languageControl = new FormControl(language);
      });
      this.authService.partnerChange.subscribe(partner => {
        this.partnerControl = new FormControl(partner);
      });

      this.languageControl.setValue(this.language);

      this.store.dispatch(setCountryAction({ payload: { country: this.country?.code.toLowerCase() } }));
      this.store.dispatch(setPartnerAction({ payload: { partner: this.partner?.partnerCode } }));
    });
  }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.router.events.subscribe((e: Event) => {
      if (e instanceof NavigationEnd) {
        if (e.urlAfterRedirects.includes('/login')) {
          this.layoutSubject.next(false);
        } else {
          this.layoutSubject.next(true);
        }
      }
    });
  }

  logout = () => {
    if(this.authService.getProvider() === "GOOGLE") {
      this.socialAuthService.signOut().then(() => {
        this.apiService.logout();
      }).catch(() => {
        this.apiService.logout();
      });
    } else {
      this.apiService.logout();
    }
  }

  setLanguage(event: any) {
    this.languageControl.setValue(event.option.value);
    this.authService.storeLanguage(event.option.value);
  }

  setCountry(event: any) {
    this.authService.storeCountry(event.option.value);
    this.store.dispatch(setCountryAction({payload: {country: event.option.value.code.toLowerCase()}}));
    this.router.navigate([HOME_PATH]);
    this.masterService.clearMasters();
  }

  setPartner(event: any) {
    this.authService.storePartner(event.option.value);
    this.store.dispatch(setPartnerAction({payload: {partner: event.option.value.name}}));
    this.router.navigate([HOME_PATH]);
    this.masterService.clearMasters();
  }

  displayLanguageName(lang: Language) {
    return lang ? lang.name: "";
  }

  displayPartnerName(partner: any) {
    return partner ? partner.partnerName : "";
  }

  displayCountryName(country: any) {
    return country ? country.name : "";
  }
}
