import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { catchError, share, tap } from "rxjs/operators";
import MasterVariable, { masterType } from "src/app/models/masterVariable.model";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: 'root'
})
export class MastersService {
  defaultMasters: {[key in masterType]: MasterVariable[] | null} = {
    'options': null,
    'field': null,
    'group': null,
    'segment': null,
    'package': null
  };
  defaultMasterRequests: {[key in masterType]: Observable<any> | null} = {
    'options': null,
    'field': null,
    'group': null,
    'segment': null,
    'package': null,
  };

  masters: {[key in masterType]: MasterVariable[] | null} = {...this.defaultMasters};
  masterRequests: {[key in masterType]: Observable<any> | null} = {...this.defaultMasterRequests};

  globalRequest: any = null;
  globalMasters: any = null;

  categories: any = null;
  categoryRequest: any = null;
  approvalStages: any = null;
  approvalStagesRequest: any = null;
  regex: any = null;
  regexRequest: any = null;
  verifications: any = null;
  verificationsRequest: any = null;

  constructor(private apiService: ApiService) {}

  clearMasters() {
    this.masters = {...this.defaultMasters};
    this.masterRequests = {...this.defaultMasterRequests};
    this.globalRequest = null;
    this.globalMasters = null;
    this.categories = null;
    this.categoryRequest = null;
    this.approvalStages = null;
    this.approvalStagesRequest = null;
    this.regex = null;
    this.regexRequest = null;
  }

  getMaster(master: masterType): Observable<any> {
    if(this.masterRequests[master]) {
      return this.masterRequests[master]!;
    }
    if(this.masters[master]) {
      return of(this.masters[master]);
    }
    return this.refreshMaster(master);
  }

  refreshMaster(master: masterType): Observable<any> {
    const thisRequest = this.apiService.getAllMasterVariables(master).pipe(
      tap((masterVariables: MasterVariable[]) => {
        this.masterRequests[master] = null;
        this.masters[master] = masterVariables;
      }),
      share(),
      catchError(err => {
        this.masterRequests[master] = null;
        this.masters[master] = null;
        return throwError(err);
      })
    );
    this.masterRequests[master] = thisRequest;
    return thisRequest;
  }

  updateMasterChildren(masterVariable: MasterVariable) {
    if('children' in masterVariable.data && masterVariable.data.children.length > 0) {
      masterVariable.data.children.forEach((child: any) => {
        const childMaster = {...masterVariable};
        childMaster.category1 = child.category1 || "";
        childMaster.category2 = child.category2 || "";
        childMaster.category3 = child.category3 || "";
        childMaster.masterType = child.modelType;
        childMaster.data = child;
        childMaster.id = child.id;
        this.addMaster(child.modelType, childMaster);
        this.updateMasterChildren(childMaster);
      });
    }
    if(masterVariable.masterType === 'field') {
      if(masterVariable.data.regexId) {
        const regexMaster = {country: masterVariable.country, partner: masterVariable.partner, id: masterVariable.data.regexId, name: masterVariable.data.regexName, regex: masterVariable.data.validation};
        this.addRegex(regexMaster);
      }
      if(masterVariable.data.optionsId) {
        const optionsMaster = {...masterVariable};
        optionsMaster.masterType = 'options';
        optionsMaster.id = masterVariable.data.optionsId;
        optionsMaster.data = {id: masterVariable.data.optionsId, modelType: 'options', name: masterVariable.data.optionsName, options: masterVariable.data.options};
        this.addMaster('options', optionsMaster);
      }
    }
  }

  saveMaster(master: masterType, masterVariable: string) {
    return this.apiService.saveMasterVariable(masterVariable).pipe(
      tap((savedMasterVariable: MasterVariable) => {
        this.addMaster(master, savedMasterVariable);
        this.updateMasterChildren(savedMasterVariable);
      })
    )
  }

  getGlobalMasters(): Observable<any> {
    if(this.globalRequest) return this.globalRequest;
    if(this.globalMasters) return of(this.globalMasters);
    const thisRequest = this.apiService.getGlobalMasterVariables().pipe(
      tap((masterVariables: MasterVariable[]) => {
        this.globalRequest = null;
        this.globalMasters = masterVariables;
      }),
      share(),
      catchError(err => {
        this.globalRequest = null;
        this.globalMasters = null;
        return throwError(err);
      })
    );
    this.globalRequest = thisRequest;
    return thisRequest;
  }

  addMaster(master: masterType, masterVariable: MasterVariable) {
    if(!(master in this.masters) || !this.masters[master]) {
      this.masters[master] = [masterVariable];
    } else {
      const thisMaster = this.masters[master];
      let found = false;
      for(let i = 0; i < thisMaster!.length; i++) {
        if(thisMaster![i].id === masterVariable.id) {
          thisMaster![i] = masterVariable;
          found = true;
          break;
        }
      }
      if(!found) {
        this.masters[master]?.push(masterVariable);
      }
    }
  }

  getCategories(): Observable<any> {
    if(this.categoryRequest) return this.categoryRequest;
    if(this.categories) return of(this.categories);
    const thisRequest = this.apiService.getCategories().pipe(
      tap((categories: any) => {
        this.categoryRequest = null;
        this.categories = categories;
      }),
      share(),
      catchError(err => {
        this.categoryRequest = null;
        this.categories = null;
        return throwError(err);
      })
    );
    this.categoryRequest = thisRequest;
    return thisRequest;
  }

  saveCategories(categories: any) {
    return this.apiService.saveCategories(categories).pipe(
      tap((categories: any) => {
        this.categories = categories;
      })
    )
  }

  getApprovalStages(): Observable<any> {
    if(this.approvalStagesRequest) return this.approvalStagesRequest;
    if(this.approvalStages) return of(this.approvalStages);
    const thisRequest = this.apiService.getApprovalStages().pipe(
      tap((approvalStages: any) => {
        this.approvalStagesRequest = null;
        this.approvalStages = approvalStages;
      }),
      share(),
      catchError(err => {
        this.approvalStagesRequest = null;
        this.approvalStages = null;
        return throwError(err);
      })
    );
    this.approvalStagesRequest = thisRequest;
    return thisRequest;
  }

  saveApprovalStages(approvalStages: any) {
    return this.apiService.saveApprovalStages(approvalStages).pipe(
      tap((savedApprovalStages: any) => {
        this.approvalStages = savedApprovalStages;
      })
    )
  }

  getRegex(): Observable<any> {
    if(this.regexRequest) return this.regexRequest;
    if(this.regex) return of(this.regex);
    const thisRequest = this.apiService.getRegex().pipe(
      tap((regex: any) => {
        this.regexRequest = null;
        this.regex = regex;
      }),
      share(),
      catchError(err => {
        this.regexRequest = null;
        this.regex = null;
        return throwError(err);
      })
    );
    this.regexRequest = thisRequest;
    return thisRequest;
  }

  addRegex(savedRegex: any) {
    if (this.regex && this.regex.length > 0) {
      let found = false;
      for (let i = 0; i < this.regex.length; i++) {
        if (this.regex[i].id === savedRegex.id) {
          this.regex[i] = savedRegex;
          found = true;
          break;
        }
        if (!this.regex[i].id) {
          this.regex.splice(i, 1);
        }
      }
      if (!found) this.regex.push(savedRegex);
    } else {
      this.regex = [savedRegex];
    }
  }

  saveRegex(regex: any) {
    return this.apiService.saveRegex(regex).pipe(
      tap((savedRegex: any) => {
        this.addRegex(savedRegex);
      })
    )
  }

  getVerifications(): Observable<any> {
    if (this.verificationsRequest) return this.verificationsRequest;
    if (this.verifications) return of(this.verifications);
    const thisRequest = this.apiService.getVerifications().pipe(
      tap((verifications: any) => {
        this.verificationsRequest = null;
        this.verifications = verifications;
      }),
      share(),
      catchError(err => {
        this.verificationsRequest = null;
        this.verifications = null;
        return throwError(err);
      })
    );
    this.verificationsRequest = thisRequest;
    return thisRequest;
  }

  addVerification(savedVerification: any) {
    if (this.verifications && this.verifications.length > 0) {
      let found = false;
      for (let i = 0; i < this.verifications.length; i++) {
        if (this.verifications[i].id === savedVerification.id) {
          this.verifications[i] = savedVerification;
          found = true;
          break;
        }
        if (!this.verifications[i].id) {
          this.verifications.splice(i, 1);
        }
      }
      if (!found) this.verifications.push(savedVerification);
    } else {
      this.regex = [savedVerification];
    }
  }

  saveVerification(verification: any) {
    return this.apiService.saveVerification(verification).pipe(
      tap((savedRegex: any) => {
        this.addVerification(savedRegex);
      })
    )
  }

  transformMasters(masterVariables: MasterVariable[]) {
    const transformedMasters: any[] = [];
    for(const masterVariable of masterVariables) {
      switch(masterVariable.masterType) {
        case 'field':
          const thisField = {
            id: masterVariable.id,
            created_at: masterVariable.created_at,
            created_by: masterVariable.created_by,
            updated_at: masterVariable.updated_at,
            updated_by: masterVariable.updated_by,
            category1: masterVariable.category1,
            category2: masterVariable.category2,
            category3: masterVariable.category3,
            ... masterVariable.data
          }
          transformedMasters.push(thisField);
          break;

        case 'segment':
          const thisSegment = {
            id: masterVariable.id,
            created_at: masterVariable.created_at,
            created_by: masterVariable.created_by,
            updated_at: masterVariable.updated_at,
            updated_by: masterVariable.updated_by,
            category1: masterVariable.category1,
            category2: masterVariable.category2,
            category3: masterVariable.category3,
            ... masterVariable.data
          }
          transformedMasters.push(thisSegment);
          break;

        case 'group':
          const thisGroup = {
            id: masterVariable.id,
            created_at: masterVariable.created_at,
            created_by: masterVariable.created_by,
            updated_at: masterVariable.updated_at,
            updated_by: masterVariable.updated_by,
            category1: masterVariable.category1,
            category2: masterVariable.category2,
            category3: masterVariable.category3,
            ... masterVariable.data
          }
          transformedMasters.push(thisGroup);
          break;

        case 'options':
          const thisOption: any = {
            id: masterVariable.id,
            created_at: masterVariable.created_at,
            created_by: masterVariable.created_by,
            updated_at: masterVariable.updated_at,
            updated_by: masterVariable.updated_by,
            category1: masterVariable.category1,
            category2: masterVariable.category2,
            category3: masterVariable.category3,
            ...masterVariable.data
          };
          transformedMasters.push(thisOption);
          break;

        default: // 'package'
          const thisPackage = {
            id: masterVariable.id,
            created_at: masterVariable.created_at,
            created_by: masterVariable.created_by,
            updated_at: masterVariable.updated_at,
            updated_by: masterVariable.updated_by,
            category1: masterVariable.category1,
            category2: masterVariable.category2,
            category3: masterVariable.category3,
            ... masterVariable.data
          }
          transformedMasters.push(thisPackage);
      }
    }
    return transformedMasters;
  }

  copyMaster(id: string) {
    return this.apiService.copyMaster(id)
      .pipe(
        tap((copiedMaster: MasterVariable) => {
          this.masters[copiedMaster.masterType]?.push(copiedMaster);
        })
      );
  }

}
