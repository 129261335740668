import { Injectable } from "@angular/core";

@Injectable()
export class FieldDataService {
  // keys can be:
  //  - id of field if it is a saved field
  //  - fieldDisplayName-fieldName for an unsaved field
  private fieldData: any = {};

  public setFieldData(fieldId: string, fieldData: any) {
    if(typeof fieldData === 'undefined') return;
    this.fieldData[fieldId] = fieldData;
  }

  public getFieldDataForField(fieldId: string) {
    return this.fieldData[fieldId];
  }

  public getFieldData() {
    return this.fieldData;
  }

  public mergeFieldData(fieldData: any) {
    this.fieldData = Object.assign(this.fieldData, fieldData);
  }
}
