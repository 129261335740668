const user: any = {
  "access_token": "eyJhbGciOiJIUzI1NiJ9.eyJwYXJ0bmVyX2NvZGUiOiJ5YWJ4ZGVtb19pbiIsInBhY2thZ2VfaWQiOiI1MzAxMjE2NGUzNzc1ZGVhIiwib3MiOiJ3ZWJfYXBwbGljYXRpb24iLCJ1c2VyX2lkIjoxLCJleHAiOjE2NDIxNzMzNDd9.m21sKtmfA1KKyvxv-EsHe-bP6U_x93kfYxKRRVljVuc",
  "refresh_token": "eyJhbGciOiJIUzI1NiJ9.eyJwYXJ0bmVyX2NvZGUiOiJ5YWJ4ZGVtb19pbiIsInBhY2thZ2VfaWQiOiI1MzAxMjE2NGUzNzc1ZGVhIiwib3MiOiJ3ZWJfYXBwbGljYXRpb24iLCJ1c2VyX2lkIjoyMywiZXhwIjoxNjQzMjc4MTA3LCJyZWZyZXNoX3Rva2VuIjp0cnVlfQ.broKDYLxwZq1UICCSudSsWtrgTt6_W-MP41TC1OhzNo",
  "user_id": 23,
  "email": "kyc_onboading_admin@yabx.com",
  "name": "KYC Admin",
  "permissions": [
    "kyc_admin",
    "review_kyc_response",
    "submit_kyc_response",
    "get_approval_path",
    "get_categories",
    "list_kyc_responses",
    "create_kyc",
    "get_master_variable_details",
    "edit_kyc",
    "edit_master_variable",
    "create_master_variable",
    "list_kyc",
    "edit_languages",
    "change_kyc_response_status",
    "edit_categories",
    "list_master_variables",
    "change_master_variable_status",
    "publish_approval_path",
    "edit_approval_path",
    "create_approval_path",
    "change_kyc_status",
    "review_kyc",
    "get_kyc_details"
  ],
  "status": "success",
  "code": 2000,
  "message": "ok",
  "partner_code": "yabxdemo_in",
  "partners": [
    {
      "id": "61d6ad013e719c621f6fa4ce",
      "partnerName": "Yabx Demo",
      "partnerCode": "yabxdemo",
      "countries": [
        "in"
      ],
      "languages": [
        "en",
        "hi"
      ]
    }
  ]
}

export default user;
