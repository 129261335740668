import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { LayoutModule } from './layout/layout.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from './i18n/translation.loader';
import { StoreModule } from '@ngrx/store';
import { globalReducer } from './kyc/store/global.reducer';
import { ApiService } from './kyc/services/api.service';
import { MastersService } from './kyc/services/masters.service';
import { ReviewsService } from './kyc/services/reviews.service';
import { GoogleLoginProvider, SocialAuthServiceConfig } from 'angularx-social-login';
import { GOOGLE_CLIENT_ID } from './static/static.data';
import { NotificationService } from './kyc/services/notification.service';
import { NgxCsvParserModule } from 'ngx-csv-parser';

@NgModule({
  declarations: [AppComponent, NotFoundComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCsvParserModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [HttpClient, NotificationService]
      }
    }),
    StoreModule.forRoot({globals: globalReducer})
  ],
  providers: [
    ApiService,
    MastersService,
    ReviewsService,
    NotificationService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [{
          id: GoogleLoginProvider.PROVIDER_ID,
          provider: new GoogleLoginProvider(GOOGLE_CLIENT_ID, {scope: 'profile email'})
        }],
        onerror: (err: any) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
